<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-card elevation-0 flat>
      <v-layout wrap justify-center>
        <v-flex text-center xs12>
          <v-img
            contain
            height="60px"
            class="align-center"
            :src="require('./../../assets/Images/orangebtn.png')"
          >
            <v-layout wrap justify-center>
              <v-flex xs12 pa-2 align-self-center text-center>
                <span class="popbold">ALL PRODUCTS</span>
              </v-flex>
            </v-layout>
          </v-img>
          <!-- <v-layout wrap justify-space-between py-2>
            <v-flex xs12 text-left text-uppercase pl-12>
              <span style="color: #000000; font-family: ibmbold">Shopping</span>
            </v-flex>
            <v-flex text-left xs12 pl-12>
              <span style="color: #9e9e9e; font-family: ibmregular"
                >WTI - All Products</span
              >
            </v-flex>
          </v-layout> -->
        </v-flex>
        <!-- <v-flex xs12>
          <v-divider></v-divider>
        </v-flex> -->
      </v-layout>
    </v-card>
    <v-layout wrap justify-center py-16>
      <v-flex xs12 md12 lg12 xl12 px-sm-12>
        <v-layout wrap justify-center>
          <v-flex
            xs12
            sm4
            md3
            lg2
            xl2
            v-for="(item, i) in products"
            :key="i"
            pa-4
            @mouseover="showByIndex = i"
            @mouseout="showByIndex = null"
          >
            <router-link :to="'/product/' + item._id">
              <v-card tile flat>
                <v-layout wrap justify-center pb-2>
                  <v-flex xs12 pa-2 v-if="item.images[0]">
                    <v-img
                      cover
                      :src="mediaURL + item.images[0]"
                      height="250px"
                    >
                      <template v-slot:placeholder>
                        <ImageLoader />
                      </template>
                    </v-img>
                  </v-flex>
                  <v-flex xs12 pa-2 v-else>
                    <v-img
                      contain
                      :src="require('./../../assets/Images/no-image.png')"
                      height="250px"
                    >
                      <template v-slot:placeholder>
                        <ImageLoader />
                      </template>
                    </v-img>
                  </v-flex>
                  <!-- <v-flex xs12> </v-flex> -->
                  <!-- <v-flex text-center xs12 pt-3>
                  <router-link :to="'/product/' + item._id">
                    <span class="ibmbold" style="color:#000">{{ item.name }}</span> <br />
                    <span
                      style="
                        font-family: ibmlight;
                        color: #404040;
                        font-size: 14px;
                      "
                      >WTI</span
                    >
                  </router-link>
                </v-flex> -->
                  <v-flex text-center xs12 pt-3>
                    <router-link :to="'/product/' + item._id">
                      <span class="popregular" style="color: #696969">{{
                        item.productType.productType
                      }}</span>
                      <br />
                      <span class="popsemibold" style="color: #000">{{
                        item.name
                      }}</span>

                      <!-- <span
                        style="
                          font-family: ibmlight;
                          color: #404040;
                          font-size: 14px;
                        "
                        >WTI</span
                      > -->
                    </router-link>
                  </v-flex>
                  <v-flex text-center xs12>
                    <span
                      style="
                        font-size: 16px;
                        font-family: poppinssemibold;
                        color: #b12704;
                      "
                    >
                      Rs. {{ item.price }}
                    </span>
                  </v-flex>
                  <v-flex text-center xs12 v-if="item.rating">
                    <v-rating
                      color="warning"
                      hover
                      length="5"
                      readonly
                      :value="item.rating"
                    ></v-rating>
                  </v-flex>
                  <!-- <v-flex xs12 align-self-center pa-4>
                        <router-link :to="'/product/' + item._id">
                          <v-layout wrap justify-center>
                            <v-flex xs8 align-self-center>
                              <v-btn tile x-small dark color="orange">
                                <span
                                  style="color: #0d5e23"
                                  class="ibmsemibold"
                                >
                                  View more..
                                </span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </router-link>
                      </v-flex> -->
                </v-layout>
              </v-card>
            </router-link>
          </v-flex>
        </v-layout>
        <v-layout
          wrap
          justify-center
          fill-height
          style="min-height: 50vh"
          v-if="products.length < 1"
        >
          <v-flex xs12 text-center align-self-center>
            <span class="itemCaption"> No Products Available </span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      value: null,
      timeout: 5000,
      msg: null,
      showByIndex: null,
      products: [],
      show: [],
      searchKey: null,
      zIndex: 0,
      items: ["1KG", "2KG", "3KG", "4KG", "5KG"],
    };
  },
  beforeMount() {
    this.searchKey = this.$route.query.searchKey;
    if (this.searchKey) this.getSearch();
    else this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/getlist",
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) this.products = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    cart(val) {
      if (val) {
        this.$router.push("/product/" + val);
      }
    },
    getSearch() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/programme/search",
        data: {
          keyword: this.searchKey,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) this.programs = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.rate {
  font-family: sofiaProLight;
  letter-spacing: 0px;
  color: #000000;
  font-size: 14px;
}
.name {
  font-family: sofiaProSemiBold;
  font-size: 20px;
  color: #1a1919;
}
.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
}
.v-select__selections {
  min-height: 10px;
}
</style>
